import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) => createStyles({
    root: {
        margin: 'auto',
        marginBottom: '10px',
        marginTop: '10px',
    },
    content: {
        color: '#89BBE6',
        '& p': { color: '#9B9B9B', },
        '& strong': { color: '#89BBE6', },
        '& li': { color: '#9B9B9B', },
    },
});
