// React imports
import React from 'react';

// Material-UI imports
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

// Gatsby imports
import { graphql } from 'gatsby';

// local imports
import { styles } from './TermsTemplate.style';
import WithTheme from 'components/WithTheme';

const TermsTemplate = ({ data, classes }) => {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;

    return (
        <WithTheme>
            <Grid container>
                <Grid item className={classes.root} xs={11}>
                    <div className={classes.content} dangerouslySetInnerHTML={{ __html: html }} />
                </Grid>
            </Grid>

            {frontmatter.footer && <Footer />}
        </WithTheme>
    );
}

export default withStyles(styles)(TermsTemplate);

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
                footer
            }
        }
    }
`;
